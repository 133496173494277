<template>
  <div class="mb-1 p-0 mx-0">
    <!--begin::Nav Tabs-->
    <ul class="dashboard-tabs nav nav-pills nav-primary row row-paddingless m-0 p-0 flex-column flex-sm-row"
        role="tablist">
      <li v-for="(row, index) in data" v-if="Number(row.parent_id) === 0" @click="modelProxy = row.id" class="nav-item d-flex col-sm flex-grow-1 flex-shrink-0 mb-3 mb-lg-0">
        <a class="nav-link border py-10 d-flex flex-grow-1 rounded flex-column align-items-center justify-content-center items-center text-navy-blue"
           :class="{active: isActive(row.id)}"
           data-toggle="pill">
          <span class="nav-icon py-2 w-auto d-flex align-items-center justify-content-center items-center">
          <span v-if="row.id === 7" v-html="getIconByKey('icons.waybill.transfer', {
                  class: 'w-35px h-35px d-inline-block object-fill cursor-pointer',
                  style: isActive(row.id) ? '' : 'opacity: 0.5;'
                })" >
          </span>
            <span v-if="row.id === 2" v-html="getIconByKey('icons.waybill.accessory', {
                  class: 'w-35px h-35px d-inline-block object-fill cursor-pointer',
                  style: isActive(row.id) ? '' : 'opacity: 0.5;'
                })" >
          </span>
          <span v-if="row.id === 1" v-html="getIconByKey('icons.waybill.fabric', {
                  class: 'w-35px h-35px d-inline-block object-fill cursor-pointer',
                  style: isActive(row.id) ? '' : 'opacity: 0.5;'
                })" >
          </span>
          <span
              class="nav-text font-size-lg py-2 font-weight-boldest text-center d-inline-block pl-2 text-xl" :id="isActive(row.id) ? 'active-navy' : ''">{{
              row.translations[0].name.toUpperCase()
            }}</span></span>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "TopWaybillNav",
  props: {
    model: {
      default: 1,
    },
    data: {
      required: true,
    }
  },
  computed: {
    modelProxy: {
      get(){
        return this.model;
      },
      set(value) {
        this.$emit('update:model', value);
      }
    }
  },
  methods: {
    isActive(value) {
      return Number(this.model) === Number(value);
    },
  }
}
</script>

<style scoped>
.svg-icon.svg-icon-2x svg {
  width: 2.0rem !important;
  height: 2.0rem !important;
}

.nav-link {
  padding: 0.5rem 0.5rem !important;
}

.dashboard-tabs li {
  cursor: pointer;
}

.text-xl {
  font-size: 1.25rem !important;
}
</style>


<style lang="scss" scoped>
@import '@/assets/sass/components/_variables.demo.scss';

.svg-icon.svg-icon-2x svg {
  width: 2.5rem !important;
  height: 2.5rem !important;
}

.nav-link {
  padding: 0.5rem 0.5rem !important;
}

.dashboard-tabs li {
  cursor: pointer;
}

.text-xl {
  font-size: 20px !important;
}

.nav.nav-pills.nav-primary .show > .nav-link .nav-icon .svg-icon svg g [fill], .nav.nav-pills.nav-primary .nav-link.active .nav-icon .svg-icon svg g [fill] {
  fill: $primary !important;
}

.nav.nav-pills.nav-primary .nav-link.active {
  color: $primary !important;
  background-color: transparent !important;
}
.nav.nav-pills.nav-primary .nav-link.active .nav-text {
  color: $primary !important;
}
.dashboard-tabs {
  .nav-item {
    margin-right: 0;
    border: none !important;
    .border {
      border-radius: 0 !important;
      border: none !important;
    }
  }
}

#active-navy {
  color: $navy-blue !important;
}

</style>
